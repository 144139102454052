import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const Service = styled.div`
  background: ${({ theme, position }) =>
    position === 1 && theme.palette.grey[100]};
  ${media.mq1`
    padding-bottom: ${({ theme }) => theme.spacing[32]}
  `};
  ${media.mq234`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) =>
      `${theme.spacing[16]} ${theme.spacing[16]} ${theme.spacing[64]}`};
    width: ${({ theme }) => `calc(100% / 3 + ${theme.spacing[16]})`};
    &:first-of-type {
      padding-left: 0;
      width: ${({ theme }) => `calc(100% / 3 - ${theme.spacing[8]})`};
    }
    &:last-of-type {
      padding-right: 0;
      width: ${({ theme }) => `calc(100% / 3 - ${theme.spacing[8]})`};
    }
  `};
`

export default Service
