import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { fluidSpacingFunc } from '~styles/helpers'

const Content = styled.section`
  display: grid;
  ${fluidSpacingFunc([{ property: 'padding', values: [6, 0] }])};
  ${media.mq1`
    grid-template-rows: 'auto auto';
    grid-template-columns: minmax(auto, 55ch);
    justify-content: center;
  `};
  ${media.mq234`
    align-items: center;
    grid-template-columns: ${({ theme }) =>
      `1fr minmax(max-content, 300px) 1fr ${theme.spacing[32]} minmax(auto, 65ch) 1fr`};
  `};
`

export default Content
