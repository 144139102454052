const messages = {
  title1: 'Personal Training',
  description1: 'Nimm den schnellsten Weg, um deine Ziele zu erreichen.',
  imageAlt1: 'Tania trainiert mit einer Kettlebell',
  button1: 'starte heute',
  title2: 'Ernährung',
  description2: 'Bleib gesund und beuge Krankheiten vor.',
  imageAlt2: 'Bananen und Haferkekse',
  button2: 'starte heute',
  title3: 'Gruppen Fitness',
  description3: 'Gruppenttraining macht Spass und motiviert.',
  imageAlt3:
    'Eine Gruppe von Menschen, die sich nach einem Training die Hände geben',
  button3: 'starte heute',
}

export default messages
