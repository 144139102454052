import React from 'react'

// import BasicPage from '~components/BasicPage'
import Layout from '~components/Layout'
import Typography from '~components/Typography'
import { NAVIGATION } from '~utils/constants'

import Services from './Services'
import Content from './Content'
import About from './About'
import Contact from './Contact'
import Header from './Header'
import messages from './messages'

const metaTags = {
  title: 'Erreiche deine Ziele effizient – Personal Trainerin in Zürich',
  description:
    'Dein individuelles Training bei dir Zuhause, Outdoor oder an einem Ort deiner Wahl. Verbessere deine Fitness, Ausdauer und Lebensqualität mit einem effizienten Trainingsplan.',
  slug: NAVIGATION.HOME.HOME,
  image: {
    url: 'PageHome/HeroHome_01--large',
    alt: 'Tania dehnt die Beinmuskulatur am Zürichsee',
  },
}

const hero = {
  pictureData: {
    path: 'PageHome',
    alt: 'Tania dehnt die Beinmuskulatur am Zürichsee',
    fallback: {
      name: 'HeroHome_01--large',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE2IDMzODQiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4OTllYTIiIGQ9Ik0wIDBoNjAxNnYzMzg0SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09Im1hdHJpeCgyMy41IDAgMCAyMy41IDExLjggMTEuOCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMzYjJmMjQiIGN4PSIxMzQiIGN5PSI4MyIgcng9IjYzIiByeT0iNDciLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC00MS44NzA2MyAtNy4zNTYxMyAxMi41NjMyNCAtNzEuNTA5MTMgMjMzLjcgMTMyLjkpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZmZmZSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgxNy45IC00MjcuMiAyNDkuOCkgc2NhbGUoNjguNDMzNDcgMzIuNzI5OCkiLz48ZWxsaXBzZSBmaWxsPSIjMjcwOTAwIiBjeD0iMTAxIiBjeT0iNTciIHJ4PSIyNCIgcnk9IjQxIi8+PHBhdGggZmlsbD0iI2MyYzViNSIgZD0iTTE1MSAyNkw4MiAxNDJsMTAxLTg3eiIvPjxwYXRoIGQ9Ik0xNjggMTIwbDI3LTQ0LTQ5LTEyeiIvPjxlbGxpcHNlIGZpbGw9IiM2MGJmZTAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEyLjk0OTc1IDM2LjUzMzY2IC01Mi40OTI5NiAtMTguNjA2NyAyOC4yIDY0LjQpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmYWE2YSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg3LjYyNTE1IC0xLjYzNDcgOS4xNTIxIDQyLjY5MDczIDE0My44IDc3LjgpIi8+PC9nPjwvc3ZnPg==',
    },
    breakpoints: {
      breakpointSmall: {
        name: 'HeroHome_01--small',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDY5IDM3MDMiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM3YzgzODIiIGQ9Ik0wIDBoMjQ3M3YzNzAzSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjIgNy4yKSBzY2FsZSgxNC40NjQ4NCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMxYTAwMDAiIGN4PSI0NiIgY3k9IjExNiIgcng9IjM5IiByeT0iNzEiLz48ZWxsaXBzZSBmaWxsPSIjZmZmYmVkIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC0xNzQuNiAzMi43IDEyNC43KSBzY2FsZSgxNzAgMzQuNDI3MTEpIi8+PHBhdGggZD0iTTE3My4yIDEyNi42bDEwLjMgODMuMy00NC43IDUuNS0xMC4zLTgzLjN6Ii8+PGVsbGlwc2UgZmlsbD0iI2VmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxMjQuODA4MDQgLjY1MzUgLS4xMDMzOCAxOS43NDQ2MiA0Ni43IDIuNSkiLz48cGF0aCBmaWxsPSIjZTZmZmZmIiBkPSJNNjMgMTQ2LjJMOTkuNyAxNTkgODcgMTk1LjggNTAuMiAxODN6Ii8+PGVsbGlwc2UgZmlsbD0iIzM3MzYzMiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxNS42NjI0NiAxMi44MTk1OCAtMjMuNTQ3MjMgMjguNzY5MSA4Ny42IDIwNi4zKSIvPjxlbGxpcHNlIGZpbGw9IiNmZmIzNjIiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoOS43NTczIDY0LjI4MzE3IC0xMS44ODk3MSAxLjgwNDcgMTE3LjYgMTQ1LjkpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZmJlNSIgY3g9IjEyOCIgY3k9IjU5IiByeD0iMTAiIHJ5PSI0OCIvPjwvZz48L3N2Zz4=',
      },
      breakpointMedium: {
        name: 'HeroHome_01--medium',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOTI1IDM5MjUiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4NTk2OTkiIGQ9Ik0wIDBoMzkyNXYzOTI1SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjcgNy43KSBzY2FsZSgxNS4zMzIwMykiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMxMjAwMDAiIGN4PSI4MSIgY3k9IjEyMyIgcng9IjM4IiByeT0iNjciLz48cGF0aCBkPSJNMTcxIDIyMWw2OS0zMy04OS04M3oiLz48ZWxsaXBzZSBmaWxsPSIjZmZmZmY0IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0uNzA4MDEgLTE5LjMwODUxIDIzNy4xNDU5IC04LjY5NTc1IDE0NiAyNDUuMykiLz48ZWxsaXBzZSBmaWxsPSIjZjZmZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC05NC4zIDcwLjkgLTYyLjIpIHNjYWxlKDI2Ljg1Mjg5IDE2My40NzA0NSkiLz48cGF0aCBkPSJNMjIyLjIgMTUxLjNsLTQ1LjMgMzIuOS0xNy0yMy41IDQ1LjItMzIuOXoiLz48ZWxsaXBzZSBmaWxsPSIjZmZmN2U5IiBjeD0iMjMiIGN5PSIyNDQiIHJ4PSI2MSIgcnk9IjQyIi8+PGVsbGlwc2UgZmlsbD0iIzVmNGU0MiIgY3g9IjEzNCIgY3k9IjIxNyIgcng9IjUxIiByeT0iMjUiLz48cGF0aCBmaWxsPSIjZmZkMmFkIiBkPSJNODkgMTU5aDc1djI2SDg5eiIvPjwvZz48L3N2Zz4=',
      },
      breakpointLarge: {
        name: 'HeroHome_01--large',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE2IDMzODQiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4OTllYTIiIGQ9Ik0wIDBoNjAxNnYzMzg0SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09Im1hdHJpeCgyMy41IDAgMCAyMy41IDExLjggMTEuOCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMzYjJmMjQiIGN4PSIxMzQiIGN5PSI4MyIgcng9IjYzIiByeT0iNDciLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC00MS44NzA2MyAtNy4zNTYxMyAxMi41NjMyNCAtNzEuNTA5MTMgMjMzLjcgMTMyLjkpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZmZmZSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgxNy45IC00MjcuMiAyNDkuOCkgc2NhbGUoNjguNDMzNDcgMzIuNzI5OCkiLz48ZWxsaXBzZSBmaWxsPSIjMjcwOTAwIiBjeD0iMTAxIiBjeT0iNTciIHJ4PSIyNCIgcnk9IjQxIi8+PHBhdGggZmlsbD0iI2MyYzViNSIgZD0iTTE1MSAyNkw4MiAxNDJsMTAxLTg3eiIvPjxwYXRoIGQ9Ik0xNjggMTIwbDI3LTQ0LTQ5LTEyeiIvPjxlbGxpcHNlIGZpbGw9IiM2MGJmZTAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEyLjk0OTc1IDM2LjUzMzY2IC01Mi40OTI5NiAtMTguNjA2NyAyOC4yIDY0LjQpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmYWE2YSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg3LjYyNTE1IC0xLjYzNDcgOS4xNTIxIDQyLjY5MDczIDE0My44IDc3LjgpIi8+PC9nPjwvc3ZnPg==',
      },
    },
  },
  textData: {
    header: messages.header,
    subHeader: messages.subHeader,
  },
}

const PageHome = ({ location }) => (
  <Layout hero={hero} metaTags={metaTags} pathname={location.pathname}>
    <Content>
      <Header as="h2" type="header2" align="center" uppercase>
        {messages.title}
      </Header>
      <Typography limit>{messages.paragraph1}</Typography>
      <Services />
      <About />
      <Typography limit>{messages.paragraph2}</Typography>
      <Contact />
    </Content>
  </Layout>
)

export default PageHome
