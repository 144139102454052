import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const ServiceGrid = styled.div`
  display: grid;
  ${media.mq234`
    grid-template-columns: 23fr 4fr 20fr;
    grid-template-rows: 1fr minmax(auto, 260px) 1fr;
    align-items: start;
  `};
`

export default ServiceGrid
