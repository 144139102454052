import styled from 'styled-components'

import NormalImg from '~components/Img'

const Img = styled(NormalImg)`
  margin: 0 auto;
  border-radius: 50%;
  object-fit: contain;
  width: 100%;
  max-width: 300px;
`

export default Img
