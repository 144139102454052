import styled from 'styled-components'

import Typography from '~components/Typography'

const ImageDescription = styled(Typography)`
  text-align: center;
  margin-top: ${(props) => props.theme.spacing[16]};
  color: ${({ theme }) => theme.palette.secondary.main};
`

export default ImageDescription
