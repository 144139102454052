import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { shadowsArray } from '~styles/constants'
import Trapezoid, { Triangle, trianglePosition } from '~components/Trapezoid'

const StyledTrapezoid = styled(Trapezoid)`
  z-index: 1;
  background: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.mq234`
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    filter: drop-shadow(${shadowsArray[0][0]}) drop-shadow(${shadowsArray[0][1]});
  `};

  & > ${Triangle} {
    fill: ${({ theme }) => theme.palette.common.white};
    ${media.mq1`
      height: 3vh;
      ${trianglePosition.top.right}
    `};
    ${media.mq234`
      width: 10%;
      ${trianglePosition.right.bottom}
    `};
  }
`

export default StyledTrapezoid
