/**
 *
 * Services
 *
 */

import React from 'react'

import { NAVIGATION } from '~utils/constants'
import Img from '~components/Img'
import Typography from '~components/Typography'

import Section from './Section'
import ServiceWrapper from './ServiceWrapper'
import Service from './Service'
import Description from './Description'
import ImgLink from './ImgLink'
import Title from './Title'
import StyledButton from './StyledButton'
import messages from './messages'

const servicesData = {
  services: [
    {
      button: messages.button1,
      description: messages.description1,
      imageData: {
        alt: messages.imageAlt1,
        path: 'PageHome',
        name: 'PersonalTraining_01',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE2IDQwMTEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM5NjkyODciIGQ9Ik0wIDBoNjAxNnYzOTk1SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09Im1hdHJpeCgyMy41IDAgMCAyMy41IDExLjggMTEuOCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMxYzBkMDMiIGN4PSI5MyIgY3k9IjEwMiIgcng9IjgwIiByeT0iNTEiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC04LjYyNzcyIDU5LjQyMTA4IC0yNTIuMzUzODIgLTM2LjY0MDg0IDE5OCAxMi45KSIvPjxlbGxpcHNlIGZpbGw9IiMyMzAwMDAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoLTU5IDExMyAtOTIuMykgc2NhbGUoMjIuNzQ0MTkgMjcuMzM4NDMpIi8+PGVsbGlwc2UgZmlsbD0iI2ZhZmZmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjUuNyAtMi40IDI4LjgpIHNjYWxlKDczLjU5NDQxIDIzLjE0MzY2KSIvPjxlbGxpcHNlIGZpbGw9IiM2ZjU1MzciIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTUuNDk4OTYgLTIzLjQ0NDk0IDE3NC44MDM4NiAtNDAuOTk5ODggMTEzLjcgMTQ0LjcpIi8+PHBhdGggZmlsbD0iI2YxZmZmZiIgZD0iTTE2MyAwaDkwdjg0aC05MHoiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC01LjY4NjczIDM5LjQ2MzE1IC04LjQ0OTIxIC0xLjIxNzU1IDEwMiAxMDYuOSkiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC0xMDEuNyAxMjAuMyAtMTAuMykgc2NhbGUoNDQuMDk5ODUgOC40MzA5KSIvPjwvZz48L3N2Zz4=',
      },
      link: NAVIGATION.SERVICE.PERSONAL_TRAINING,
      title: messages.title1,
    },
    {
      button: messages.button2,
      description: messages.description2,
      imageData: {
        alt: messages.imageAlt2,
        name: 'Nutrition_01',
        path: 'PageHome',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNDM5IDIyOTMiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiNiNDliODAiIGQ9Ik0wIDBoMzQzOXYyMjgzSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjcgNi43KSBzY2FsZSgxMy40MzM2KSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iIzA4MDAwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgxNDYuOSAyNC4yIDYyLjkpIHNjYWxlKDI5LjY4NDU0IDMwLjU5Mzc3KSIvPjxlbGxpcHNlIGN4PSIxODAiIGN5PSI0OSIgcng9IjM0IiByeT0iMjQiLz48ZWxsaXBzZSBmaWxsPSIjMjcwNDAwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDE0Ni4xIDcxLjcgMTAzLjEpIHNjYWxlKDI4LjUxNTQ3IDIyLjU1NTUpIi8+PGVsbGlwc2UgZmlsbD0iI2ZjZjZmOCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgtNi45IDEwNjEuNSAtMjAwNi4zKSBzY2FsZSgzNC4xOTI5OSA2NS41NjE3MykiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0xMi43OTkxOCAtLjg5NSAyLjc5NDE1IC0zOS45NTgyNSAxLjUgNzEuOSkiLz48ZWxsaXBzZSBmaWxsPSIjZjJlYmU4IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDIxLjkwNjggNC41MzY2OCAtMTEuMDMyNTYgNTMuMjc0MiAxMzcuNCAxMTIuMykiLz48ZWxsaXBzZSBmaWxsPSIjMGIwMDAwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDI5LjQzMTAzIDIuODA2ODYgLTEuODg1OTggMTkuNzc1MyA4Ny4yIDE2OSkiLz48ZWxsaXBzZSBmaWxsPSIjZmZlYmE5IiBjeD0iMzIiIGN5PSIxNjkiIHJ4PSIyMSIgcnk9IjE1MyIvPjwvZz48L3N2Zz4=',
      },
      link: NAVIGATION.SERVICE.NUTRITION,
      title: messages.title2,
    },
    {
      button: messages.button3,
      description: messages.description3,
      imageData: {
        alt: messages.imageAlt3,
        name: 'GroupFitness_01',
        path: 'PageHome',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MDAwIDI2NjciPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM5MjcxNTIiIGQ9Ik0wIDBoNDAwMHYyNjU2SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjggNy44KSBzY2FsZSgxNS42MjUpIiBmaWxsLW9wYWNpdHk9Ii41Ij48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDcuMDk0NjIgLTQyLjg1NTEgNzMuOTkyODQgMTIuMjQ5NDQgMTEuOSA0NCkiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDgxLjgwMDU1IDM1LjA1OTc2IC0xNi40OTg2MSAzOC40OTQxNSAyMi4zIDE0My45KSIvPjxwYXRoIGZpbGw9IiNmZmJhNTAiIGQ9Ik0yOTYuNSA3MmwtODAuNCA2Mi44TDE1MS41IDUybDgwLjQtNjIuOHoiLz48cGF0aCBkPSJNMTI2IDVoMjN2ODloLTIzeiIvPjxlbGxpcHNlIGZpbGw9IiNmNGZmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEuNjAzOTggLTE5LjQxODM2IDExMi4zMjcyMyAtOS4yNzgzNiAyMC4xIDQ4LjQpIi8+PGVsbGlwc2UgZmlsbD0iIzg0MjMwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg1LjM0Njc1IDU0LjczNjgzIC00My43MDAwMSA0LjI2ODY2IDIxNSAxNDEuOCkiLz48ZWxsaXBzZSBmaWxsPSIjMDcwMDAwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC00OC4wMDQ2MiAtMi4wNTE1NyAuOTA2NjIgLTIxLjIxNDA0IDkwLjQgNykiLz48ZWxsaXBzZSBjeD0iMjAiIGN5PSIxNDUiIHJ4PSI0NCIgcnk9IjQ0Ii8+PC9nPjwvc3ZnPg==',
      },
      link: NAVIGATION.SERVICE.GROUP_FITNESS,
      title: messages.title3,
    },
  ],
}

const Services = () => (
  <Section>
    <ServiceWrapper>
      {servicesData.services.map(
        ({ title, description, link, imageData, button }, index) => (
          <Service key={link} position={index}>
            <ImgLink to={link}>
              <Img imageData={imageData} />
            </ImgLink>
            <Description>
              <Title as="h3" type="header4" uppercase align="center">
                {title}
              </Title>
              <Typography align="center">{description}</Typography>
            </Description>
            <StyledButton to={link} align="center">
              {button}
            </StyledButton>
          </Service>
        ),
      )}
    </ServiceWrapper>
  </Section>
)

export default Services
