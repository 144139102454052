import styled from 'styled-components'
import { Link } from 'gatsby'

import { media } from '~styles/mediaQuery'

const ImgWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  ${media.mq1`
    margin-bottom: ${({ theme }) => theme.spacing[32]}
  `};
  ${media.mq234`
    grid-column: 2/3;
  `};
`

export default ImgWrapper
