import styled from 'styled-components'

import { Link } from 'gatsby'

const ImgLink = styled(Link)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`

export default ImgLink
