import styled from 'styled-components'

import Button from '~components/Button'
import { media } from '~styles/mediaQuery'

const StyledButton = styled(Button)`
  ${media.mq234`
    margin-top: auto;
  `};
`

export default StyledButton
