const messages = {
  header: 'Be the Best Version of Yourself',
  subHeader: 'eat well, work out, smile more',
  title: 'Zusammen an deinem Erfolg arbeiten',
  paragraph1:
    'Mein Ziel ist es Menschen dabei zu helfen die eigene Gesundheit zu fördern, die Lebensqualität zu erhöhen und sich im eigenen Körper wohl zu fühlen. Ich bringe dich dazu an dich selbst zu glauben und an das was dein Körper alles errreichen kann. Mit dem richtigen Training und mit einer ausgewogenen Ernährung erreichen wir deine Ziele.',
  paragraph2:
    'Bewegung und körperliche Aktivität haben eine Vielzahl an positiven Auswirkungen auf den Körper. Möchtest du deine Gewohnheiten änderen, Gewicht verlieren, Muskeln aufbauen oder ein bestimmtes sportliches Ziel erreichen? Dann bist du bei mir genau richtig.',
}

export default messages
