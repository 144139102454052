/**
 *
 * About
 *
 */

import React from 'react'

import MaxWidthWrapper from '~components/MaxWidthWrapper'
import { NAVIGATION } from '~utils/constants'

import ImgWrapper from './ImgWrapper'
import DescriptionText from './DescriptionText'
import ImageDescription from './ImageDescription'
import Img from './Img'
import Section from './Section'
import Grid from './Grid'
import messages from './messages'

const aboutData = {
  description: messages.descriptionText1,
  imageData: {
    alt: messages.imageAlt,
    name: 'TaniaFerreira_01',
    path: 'PageHome',
    placeholder:
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMzE3IDMzMTciPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiNhMjlmYTYiIGQ9Ik0wIDBoMzMxN3YzMzE3SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjUgNi41KSBzY2FsZSgxMi45NTcwMykiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMzZDAwMDAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoMTI3LjggNy43IDExNykgc2NhbGUoNzAuOTAzNjUgODcuNDU0NDEpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgxMi4zIC0yOS41IDYyMS41KSBzY2FsZSgyNTUgNTMuNDk5MSkiLz48ZWxsaXBzZSBmaWxsPSIjNGExOTAwIiBjeD0iMTIzIiBjeT0iODQiIHJ4PSI0MCIgcnk9IjQwIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTI1OS40IDE3Mi45bDQ2IDQ0LjQtODQuOCA4Ny44LTQ2LTQ0LjR6Ii8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxMzkuMjE2ODkgMTM1Ljg1NjA3IC0xOC42NzU5OSAxOS4xMzggMjUgMjQyLjIpIi8+PGVsbGlwc2UgZmlsbD0iYXp1cmUiIGN4PSIzNSIgY3k9IjI5IiByeD0iNjMiIHJ5PSI2MyIvPjxlbGxpcHNlIGZpbGw9IiM2ZWRiZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMy45NzM2MSAxOC43NTMxNiAtNDkuODM5MTEgMTAuNTYwNDMgNDAuNyAxMzkuMikiLz48ZWxsaXBzZSBmaWxsPSIjMTYwMDAwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDQ0LjUgLTE1NSAyNzQuNykgc2NhbGUoNjguODk2IDE3LjI4NzE3KSIvPjwvZz48L3N2Zz4=',
  },
  imageDescription: messages.imageDescription,
}

const About = () => (
  <Section>
    <MaxWidthWrapper>
      <Grid>
        <ImgWrapper to={NAVIGATION.ABOUT.ABOUT}>
          <Img imageData={aboutData.imageData} />
          <ImageDescription as="h2" type="header2">
            {aboutData.imageDescription}
          </ImageDescription>
        </ImgWrapper>
        <DescriptionText>{aboutData.description}</DescriptionText>
      </Grid>
    </MaxWidthWrapper>
  </Section>
)

export default About
