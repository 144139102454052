import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { maxWidthStyle } from '~components/MaxWidthWrapper'

const Content = styled.section`
  ${maxWidthStyle};
  ${media.mq1`
    max-width: 100%;
  `};
`

export default Content
