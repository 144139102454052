/**
 *
 * Contact
 *
 */

import React from 'react'

import Button from '~components/Button'
import { NAVIGATION } from '~utils/constants'

import Section from './Section'
import StyledTrapezoid from './StyledTrapezoid'
import Grid from './Grid'
import Image from './Image'
import Content from './Content'
import Description from './Description'

const contactData = {
  button: 'Kontakt',
  description:
    'Möchtest du kombinieren und ein individuelles Paket haben?\n\nFrag einfach nach! Wir finden eine Lösung',
  imageData: {
    alt: 'Tania die einer Kundin beim Kopfstand hilft',
    name: 'Headstand_01',
    path: 'PageHome',
    placeholder:
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNjM4IDM2MzgiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1YzZlNDgiIGQ9Ik0wIDBoMzYzOHYzNjM4SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjEgNy4xKSBzY2FsZSgxNC4yMTA5NCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNkMWZmOGQiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMTE5LjgyNzAyIC00NS43OTY2IDExLjg5OTk1IDMxLjEzNjI4IDQ1LjQgMTkyLjcpIi8+PGVsbGlwc2UgZmlsbD0iI2RlZWY4MyIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxMS4yNzM4IDM1Ljc1NTk1IC03OC4xMjcxNSAyNC42MzM0IDIyNiAxODMuNykiLz48ZWxsaXBzZSBmaWxsPSIjMTExMjI0IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC0xNzYuNCAzMi42IDM1LjYpIHNjYWxlKDIzNy40Mjk1MiA1OC4wMjU2OSkiLz48ZWxsaXBzZSBmaWxsPSIjYjllZjIwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0zOC40MDQzOSAtNjMuNzM3NDIgNDQuMDY0OCAtMjYuNTUwODMgNDQuNiAyMjYuNykiLz48ZWxsaXBzZSBmaWxsPSIjZTRmNmZmIiBjeD0iMTc0IiByeD0iNzciIHJ5PSIxNCIvPjxlbGxpcHNlIGZpbGw9IiMwMDAwMDciIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIzLjMgNiAxNTQpIHNjYWxlKDIyLjExNTI2IDU2LjI4ODI0KSIvPjxlbGxpcHNlIGZpbGw9IiM3YTk0ZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTIzLjg1IC01LjUwNjIgNy45OTA5IC0zNC42MTIzOCAxMTMuOCAxNjcpIi8+PHBhdGggZmlsbD0iIzAwMDAyYSIgZD0iTTIwNCAxODBsLTExIDQ1IDQwIDIxeiIvPjwvZz48L3N2Zz4=',
  },
  link: NAVIGATION.CONTACT.CONTACT,
}

const Contact = () => (
  <Section>
    <Grid>
      <Image imageData={contactData.imageData} />
      <StyledTrapezoid>
        <Content>
          <Description preWrap>{contactData.description}</Description>
          <Button to={contactData.link} align="center">
            {contactData.button}
          </Button>
        </Content>
      </StyledTrapezoid>
    </Grid>
  </Section>
)

export default Contact
