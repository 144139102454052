import styled from 'styled-components'

import Typography from '~components/Typography'
import { media } from '~styles/mediaQuery'

const DescriptionText = styled(Typography)`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.palette.text.primary.contrastText};
  ${media.mq234`
    grid-column: 5 / 6;
  `};
`

export default DescriptionText
