import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const ServiceWrapper = styled.div`
  ${media.mq234`
    display: flex;
  `};
`

export default ServiceWrapper
