import styled from 'styled-components'

import Typography from '~components/Typography'

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`

export default Title
